<template>
  <div class='px-14 grid grid-cols-1'>
    <div
      class='w-full h-full justify-center sm:justify-items-center 2xl:justify-end grid md:grid-cols-2 sm:grid-cols-1 lg:flex lg:space-x-6'
    >
      <button
        type='button'
        class='inline-flex items-center px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 w-36 mt-2'
        @click.prevent='sendRemindToOperator'
        :disabled='loading'
        v-if='hasOperator'
      >
        <IconNotification class='h-8 w-8' v-show='!loading' />
        <i
          v-show='loading'
          class='fas fa-circle-notch fa-spin mr-2 h-4 w-4 text-white'
        ></i>
        {{ $t('post.page.button.restart') }}
      </button>
      <div class='w-36 mt-2'>
        <ButtonEditStatus class='w-full' :post='post' />
      </div>
      <div class='w-36 mt-2'>
        <ButtonEditPriority
          class='w-full'
          :post='post'
        ></ButtonEditPriority>
      </div>
      <div class='mb-2'>
        <router-link
          class='inline-flex items-center border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mt-2 w-36 my-0 px-4 py-0 h-full'
          :to='`/post/${post.id}/edit`'
        >
          <button
            type='button'
            class='inline-flex items-center border border-transparent font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 h-full px-4  py-0'
          >
            <IconWrite class='mr-2 h-4 w-4' />
            {{ $t('post.page.button.edit') }}
          </button>
        </router-link>
      </div>
      <button
        type='button'
        class='buttonExport inline-flex items-center px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 w-36 mt-2'
        @click.prevent='exportPDF()'
      >
        <IconDownload class='h-5 w-5'  v-show='!loadingDownloadPdf'  />
        <i
          v-show='loadingDownloadPdf'
          class='fas fa-circle-notch fa-spin mr-2 h-4 w-4 text-white'
        ></i>
        {{ $t('post.page.button.export') }}
      </button>
    </div>
    <PostHeaderTitle :last-change='lastChange' :post='post'  />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import ButtonEditStatus from '@/components/UI/ButtonEditStatus'
import ButtonEditPriority from './ButtonEditPriority'
import PostHeaderTitle from '@/components/Model/Post/PostHeaderTitle'

export default {
  name: 'PostDetailsHeader',
  props: ['post','loadingDownloadPdf'],
  components: {
    PostHeaderTitle,
    ButtonEditStatus,
    ButtonEditPriority
  },
  data() {
    return {
      loading: false,
      loadingDownload: false
    }
  },
  computed: {
    ...mapState('post', {
      history: (state) => state.history,
      hasOperator: (state) => state.hasOperator
    }),
    lastChange() {
      return this.history[0]
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions('post', ['updatePriority']),

    ...mapActions('post', ['remindOperator', 'getPostHistories']),
    exportPDF() {
      this.$emit('export-pdf')
    },
    sendRemindToOperator() {
      this.loading = true
      this.remindOperator(this.post.id)
        .then(() => {
          this.getPostHistories(this.post.id)
          this.$store.dispatch('toast', {
            type: 'success',
            title: this.$t('post.operator.reminded')
          })
        })
        .catch((e) => {
          this.$store.dispatch('dispatchError', e)
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false
          }, 2000)
        })
    }
  }
}
</script>

<style scoped>
.buttonExport{
  min-height: 2em;
}
</style>

