<script>
import HistoryRow from './HistoryRow'
export default {
  name: 'HistoryMixin',
  props: ['history'],
  components: {
    HistoryRow,
  },
}
</script>
