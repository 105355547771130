<template>
    <HistoryRow>
        <template v-slot:icon>
            <span
                class="h-8 w-8 rounded-full bg-warning-500 flex items-center justify-center ring-8 ring-white text-white"
            >
                <IconNotification class="w-5 h-5 text-white"></IconNotification>
            </span>
        </template>
        <div v-if="history.user && history.author_type === 1">
            <div
                class="text-sm text-gray-500"
                v-if="history.old_value && history.new_value"
            >
                <a href="#" class="font-medium text-gray-900">
                    {{ history.user.name }} </a
                >{{ operatorChanged }}
            </div>
            <div
                class="text-sm text-gray-500"
                v-else-if="!history.old_value && history.new_value"
            >
                <a href="#" class="font-medium text-gray-900">
                    {{ history.user.name }} </a
                >{{ operatorOtherAction }}
            </div>
            <div class="text-sm text-gray-500" v-else>
                <a href="#" class="font-medium text-gray-900">
                    {{ history.user.name }} </a
                >{{ noOperator }}
            </div>
        </div>
        <div v-else>
            <div class="text-sm text-gray-500">
                {{ newOperator }}
            </div>
        </div>
        <div class="text-right text-sm whitespace-nowrap text-gray-500">
            {{ moment(history.created_at).format('D MMMM') }}
        </div>
    </HistoryRow>
</template>

<script>
import HistoryMixin from './HistoryMixin'

export default {
    name: 'PostHistory',
    mixins: [HistoryMixin],
    computed: {
        operatorChanged() {
            return `${this.$t(`post.page.history.operator.changed`)} ${this.$t(
                'post.page.history.from'
            )} ${this.history.old_value} ${this.$t('post.page.history.to')} ${
                this.history.new_value
            }`
        },
        operatorOtherAction() {
            return `${this.$t(
                `post.page.history.operator.${this.history.action}`
            )} ${this.history.new_value}`
        },
        noOperator() {
            return this.$t('post.page.history.operator.no')
        },
        newOperator() {
            return `${this.$t(`post.page.history.operator.new`)} ${
                this.history.new_value
            }`
        }
    }
}
</script>

<style scoped></style>
