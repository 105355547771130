<template>
  <div class='flex items-center space-x-5 space-y-6 w-full h-full'>
    <div class='flex-shrink-0'>
      <div class='relative'>
        <span
          class='h-10 w-10 sm:h-20 sm:w-20 hidden sm:flex rounded-full bg-blue-500 items-center justify-center ring-8 ring-white'
        >
                        <i :class="`${post.status.icon}`"
                           class='text-white'></i>
                    </span>
      </div>
    </div>
    <div>
      <h1 class='text-lg sm:text-2xl font-bold text-blue-500'>
        {{ post.title }}
      </h1>
      <div>
        <p class='text-sm font-medium text-gray-500'>
          {{ $t('post.page.create_by') }}
          <span
            class='text-sm font-medium text-gray-500'
            v-if='post.author_type === 1'
          >
                            {{ $t('post.page.the_operator') }}</span
          >
          <span
            class='text-sm font-medium text-gray-500'
            v-if='
                                post.author_type === 2 &&
                                !(post.created_by || post.author_email)
                            '
          >
                            {{ $t('post.page.the_citizen') }}</span
          >
          <a href='#' class='text-gray-900'>
                            <span v-if='post.created_by'>
                                {{ post.created_by.name }}
                            </span>
            <span v-else-if='post.author_email'>
                                {{ post.author_email }}
                            </span>
            <span
              v-else
              class='text-sm font-medium text-gray-500'
            >
                                {{ $t('post.page.unknown_citizen') }}
                            </span>
          </a>

          - {{ moment(post.created_at).fromNow() }},

          <time :datetime='post.created_at'>
            {{ moment(post.created_at).format('LLL') }}
          </time>
        </p>
      </div>
      <p
        class='text-sm font-medium text-gray-500'
        v-if="
                        lastChange &&
                        lastChange.id &&
                        lastChange.action != 'create'
                    "
      >
        {{ $t('post.page.edit_by') }}
        <a href='#' class='text-gray-900'>
          {{ lastChange.user ? lastChange.user.name : '' }}
        </a>

        - {{ moment(lastChange.updated_at).fromNow() }},

        <time :datetime='post.updated_at'>
          {{ moment(lastChange.updated_at).format('LLL') }}
        </time>
      </p>
      <div
        class="mt-2 grid grid-cols-1 sm:grid-cols-2 xl:flex items-center sm:space-x-2 lg:col-start-3 lg:col-span-1"
      >
        <div class='flex space-x-3'>
                        <span class='text-blue-700 font-medium text-sm'>
                            {{ $t('post.page.postsfilters.status') }}:
                        </span>
          <i :class='post.status.icon' class='text-blue-500'></i>
          <span class='text-blue-700 text-sm font-medium'>
                            <!-- {{post.status.name}}       -->
                            {{
              $t(
                `post.status.${post.status.translations[0].name.toLowerCase()}`
              )
            }}
                        </span>
        </div>
        <div v-if='post.priority' class='flex space-x-2 align-items-center'>
                        <span class='sm:ml-3 text-blue-700 font-medium text-sm'>
                            {{ $t('post.page.postsfilters.priority') }}:
                        </span>

          <!-- no priority -->
          <span
            v-if='post.priority === 0'
            class='text-blue-700 text-sm font-medium'
          >
                            {{ $t(`post.page.priority.${post.priority}`) }}
                        </span>
          <!-- priority observation -->
          <IconSearch
            v-if='post.priority === 1'
            class='h-5 w-5 text-blue-500'
          />
          <span
            v-if='post.priority === 1'
            class='text-blue-700 text-sm font-medium'
          >
                            {{ $t(`post.page.priority.${post.priority}`) }}
                        </span>

          <!-- priority mineur -->
          <IconCheck
            v-if='post.priority === 2'
            class='h-5 w-5 text-blue-500'
          />
          <span
            v-if='post.priority === 2'
            class='text-blue-700 text-sm font-medium'
          >
                            {{ $t(`post.page.priority.${post.priority}`) }}
                        </span>

          <!-- priority modere-->
          <IconProgress
            v-if='post.priority === 3'
            class='h-5 w-5 text-blue-500'
          />
          <span
            v-if='post.priority === 3'
            class='text-blue-700 text-sm font-medium'
          >
                            {{ $t(`post.page.priority.${post.priority}`) }}
                        </span>

          <!-- priority critique -->
          <IconFire
            v-if='post.priority === 4'
            class='h-5 w-5 text-red-500'
          />
          <span
            v-if='post.priority === 4'
            class='text-red-700 text-sm font-medium'
          >
                            {{ $t(`post.page.priority.${post.priority}`) }}
                        </span>
        </div>
        <div class='flex align-items-center space-x-3 '>
          <div>
            <IconModif
              v-if='post.comments.length > 0'
              class=' text-blue-500 xl:ml-4 h-5 w-5'

            />
          </div>

          <a
            @click.prevent="
                                $store.dispatch('scrollTo', 'commentaires')
                            "
            v-if='post.comments.length > 0'
            class='flex inline-flex text-blue-700 text-sm font-medium'
          >
            <span>{{ post.comments.length }}</span>

            <div class='ml-1'>
                                <span v-if='post.comments.length > 1'>{{
                                    $t('post.page.comments.internal_notes')
                                  }}</span>
              <span v-else>{{
                  $t('post.page.comments.internal_note')
                }}</span>
            </div>
          </a>
        </div>
        <div class='flex align-items-center space-x-3 '>
          <div>
            <IconChat
              v-if='post.messages.length > 0'
              class="text-blue-500 h-4 w-4 "

            />
          </div>
          <a
            @click.prevent="
                                $store.dispatch('scrollTo', 'messages')
                            "
            v-if='post.messages.length > 0'
            class='flex inline-flex text-blue-700 text-sm font-medium'
          >
                            <span v-if='post.messages.length > 1'>
                                {{ post.messages.length }}
                                {{ $t('post.page.messages.messages') }}
                            </span>
            <span v-else>
                                {{ post.messages.length }}
                                {{ $t('post.page.messages.message') }}
                            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PostHeaderTitle',
  props: ['lastChange', 'post']
}
</script>
