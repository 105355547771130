<template>
  <HistoryRow :separator="history.action !== 'create'">
    <template v-slot:icon>
      <span
        class="h-8 w-8 rounded-full bg-success-500 flex items-center justify-center ring-8 ring-white text-white"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
          v-if="history.action === 'create'"
        >
          <path
            fill-rule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clip-rule="evenodd"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
          v-if="history.action === 'update'"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
          />
        </svg>
      </span>
    </template>
    <div>
      <p class="text-sm text-gray-500" >
        <a href="#" class="font-medium text-gray-900" v-if="history.user  && history.author_type===1">
          {{ history.user.name }}
        </a>
        <a href="#" class="font-medium text-gray-900" v-if="history.author_type===2 || !history.author_type">
          {{ history.citizen && history.citizen.name? history.citizen.name : history.new_value && history.new_value.author_mail? history.new_value.author_mail : $t('post.page.history.citizen')  }}
        </a>
        {{ $t(`post.page.history.post.${history.action}`) }}
      </p>
    </div>
    <div class="text-right text-sm whitespace-nowrap text-gray-500">
      {{ moment(history.created_at).format('D MMMM') }}
    </div>
  </HistoryRow>
</template>

<script>
import HistoryMixin from './HistoryMixin'

export default {
  name: 'PostHistory',
  mixins: [HistoryMixin],
}
</script>

<style scoped></style>
