<template>
  <div>
    <b>{{ value.label }}</b
    ><br />
    <div class="sm:col-span-2">
      <dd
        v-for="(file, index) in value.value"
        :key="index"
        class="mt-1 text-sm text-gray-900"
      >
        <UploadedFile :file="getMedia(file)" ></UploadedFile>
      </dd>
    </div>
  </div>
</template>

<script>
import UploadedFile from '@/components/Model/File/UploadedFile'

export default {
  name: 'FileUploadAttribute',
  props: ['value', 'medias'],
  components: {
    UploadedFile,
  },
  methods: {
    getMedia(id) {
      return this.medias.filter((media) => media.id == id)[0]
    },
  },
}
</script>

<style scoped></style>
