<template>
  <!-- Historique des modifications -->
  <div class='bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 mt-5'>
    <h2 id='test-title' class='text-lg font-medium text-blue-500'>
      {{ $t('post.page.history.history') }}
    </h2>
    <div class='mt-4 flow-root'>
      <ul role='list' class='-mb-8 mt-2'>
        <template v-if='postHistories && postHistories.length > 0'>
          <component
            :is='history.entity'
            :history='history'
            v-for='history in histories'
            :key='history.id'
          />
        </template>
        <template v-else>
          <li class='mb-5'>Aucun historique</li>
        </template>
      </ul>
    </div>
    <div
      v-if='postHistories && postHistories.length > 5'
      class='mt-6 flex flex-col justify-stretch'
    >
      <button
        v-if='loadHistory'
        @click='loadMore()'
        type='button'
        class='inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
      >
        {{ $t('post.page.history.more') }}
      </button>
      <button
        v-else
        @click='loadLess()'
        type='button'
        class='inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
      >
        {{ $t('post.page.history.less') }}
      </button>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import PostHistory from './History/PostHistory'
import OperatorHistory from './History/OperatorHistory'
import CommentHistory from './History/CommentHistory'
import MessageHistory from './History/MessageHistory'
import PriorityHistory from './History/PriorityHistory'
import StatusHistory from './History/StatusHistory'

export default {
  name: 'PostHistories',
  data: function() {
    return {
      loading: true,
      display: 5,
      loadHistory: true
    }
  },
  components: {
    Post: PostHistory,
    Comment: CommentHistory,
    Message: MessageHistory,
    Operator: OperatorHistory,
    Priority: PriorityHistory,
    Status: StatusHistory
  },
  computed: {
    ...mapState('post', {
      postHistories: (state) => state.history,
      post: (state) => state.post
    }),
    histories() {
      if (this.display > this.postHistories.length) {
        this.loadHistory = false
        return this.postHistories
      } else {
        return this.postHistories.slice(0, this.display)
      }
    }
  },
  methods: {
    loadMore() {
      this.display += 5
    },
    loadLess() {
      this.display = 5
      this.loadHistory = true
    }
  },
  created() {
  }
}
</script>
