<template>
    <div>
        <b>{{ value.label }}</b
        ><br />
        <p v-html="value.value">{{ value.value }}</p>
    </div>
</template>

<script>
export default {
    name: 'ClassicAttribute',
    props: ['value']
}
</script>

<style scoped></style>
