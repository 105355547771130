<template>
  <div class='bg-gray-100'>
    <bs-modal
      :id='id'
      v-if='post && citizen'
      type='modal-dialog modal-xl'
      class='rounded-lg overflow-hidden shadow mt-5 w-full p-4 overflow-y-scroll my-2'
    >
      <template #header class='is-fullwidth'>
        <div
          class='flex-shrink-0 flex justify-between items-center w-full rounded-lg bg-white overflow-hidden shadow'
        >
                    <span class='flex justify-center items-center px-4'>
                        <IconUserPlain class='h-20 w-20' />
                        <p
                          class='ml-2 text-xl font-bold text-blue-500 sm:text-2xl text-left'
                        >
                            {{
                            citizen.name
                              ? citizen.name
                              : citizen.email
                                ? citizen.email
                                : $t('post.page.unknown')
                          }}
                        </p>
                    </span>
          <span>
                        <i
                          @click='cancel'
                          class='fal fa-times text-blue-500 mr-4'
                        ></i>
                    </span>
        </div>
      </template>

      <section
        class='rounded-lg flex flex-column justify-center gap-2 w-full shadow bg-white overflow-hidden my-2'
      >
        <div class='px-4 py-4 sm:px-6'>
          <h2 class='text-lg leading-6 font-medium text-blue-500'>
            {{ $t('post.page.citizenprofile.profile') }}
          </h2>
        </div>
        <div
          class='border-t border-gray-200 flex flex-wrap justify-around items-center gap-2 w-full py-2'
        >
          <div>
            <p
              v-if='
                                citizen.email &&
                                citizen.email === post.author_email
                            '
            >
              <strong>{{
                  $t('post.page.citizenprofile.email')
                }}</strong>
              {{ citizen.email }}
            </p>
            <div v-else>
              <p>
                <strong>{{
                    $t('post.page.citizenprofile.account_email')
                  }}</strong>
                {{ citizen.email }}
              </p>
              <p>
                <strong
                >{{
                    $t(
                      'post.page.citizenprofile.entered_email'
                    )
                  }}
                </strong>
                {{ post.author_email }}
              </p>
            </div>
            <p>
              <strong>{{
                  $t('post.page.citizenprofile.verified_email')
                }}</strong>
              {{
                citizen.email_verified_at
                  ? $t('post.page.statistics.label.boolean.1')
                  : $t('post.page.statistics.label.boolean.0')
              }}
            </p>
            <p v-if='citizen.phone'>
              <strong>{{
                  $t('post.page.citizenprofile.phone')
                }}</strong>
              {{ citizen.phone }}
            </p>
          </div>
          <div>
            <p>
              <strong>{{
                  $t('post.page.citizenprofile.token')
                }}</strong>
              <span v-if='citizen.firebase_token'>{{
                  $t('post.page.statistics.label.boolean.1')
                }}</span>
              <span v-else>{{
                  $t('post.page.statistics.label.boolean.0')
                }}</span>
            </p>
            <p>
              <strong>{{
                  $t('post.page.citizenprofile.firebase')
                }}</strong>
              <span v-if='citizen.is_notif_activated_at'>{{
                  $t('post.page.statistics.label.boolean.1')
                }}</span>
              <span v-else>{{
                  $t('post.page.statistics.label.boolean.0')
                }}</span>
            </p>
          </div>
        </div>
      </section>
      <section
        class='rounded-lg flex flex-column justify-center gap-2 w-full shadow bg-white my-2'
      >
        <div class='px-4 py-4 sm:px-6'>
          <h2 class='text-lg leading-6 font-medium text-blue-500'>
            {{ $t('post.page.citizenprofile.posts') }}
          </h2>
        </div>
        <PostsTable
          :citizenPosts='citizenPosts'
          class='border-t border-gray-200'
        />
      </section>
    </bs-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BsModal from '@/components/UI/BsModal'
import ModalMixin from '@/components/UI/ModalMixin'
import PostsTable from '@/components/Model/Post/PostsTable'

export default {
  name: 'CitizenProfile',
  components: {
    BsModal,
    PostsTable
  },
  props: ['post'],
  mixins: [ModalMixin],
  data() {
    return {
      id: 'CitizenProfile',
      loading: false,
      citizen: {},
      test: false,
      citizenPosts: {}
    }
  },
  created() {
    this.getDataCitizen()
  },
  methods: {
    ...mapActions('company', {
      getcitizen: 'getCitizen'
    }),
    getDataCitizen() {
      if (this.post.author_id) {
        this.citizen = this.post.citizen_account_with_device
        this.citizenPosts = this.citizenPostById()
      } else if (this.post.author_email) {
        this.citizen = this.post.citizen_without_account
        this.citizenDevice()
        this.citizenPosts = this.citizenPostByMail()
      } else {
        this.citizenProfile()
        if(this.post.citizen_token && this.post.citizen_token.token) {
          this.citizenPosts = this.citizenPostByToken()
        }
      }
      if(this.post.device && this.post.device.firebase_token){
        this.citizen.firebase_token = this.post.device.firebase_token
      }
    },
    citizenProfile() {
      this.citizen.email = this.post.author_email
      this.citizen.phone = this.post.author_phone
      this.citizenDevice()
    },
    citizenDevice() {
      if(this.post.device && this.post.device.firebase_token){
      this.citizen.firebase_token = this.post.device.firebase_token
      this.citizen.is_notif_activated_at =
        this.post.device.is_notif_activated_at
      }
    },
    citizenPostById() {
      return {
        by_citizen_id: this.post.author_id
      }
    },
    citizenPostByMail() {
      return {
        by_citizen_mail: this.post.author_email
      }
    },
    citizenPostByToken() {
      return {
        with_token: this.post.citizen_token.token
      }
    },
    trigger: function() {
      this.modal.show()
    },
    cancel: function() {
      this.modal.hide()
    }
  },
  mounted() {
    this.create(this.id, {
      keyboard: false
    })
  }
}
</script>
