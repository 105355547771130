<template>
    <div
        class="flex flex-row items-center space-x-5 mt-4 rounded-md justify-between"
    >
        <button
            type="button"
            data-toggle="dropdown"
            aria-expanded="false"
            class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2"
        >
            {{ $t('template.name') }}
            <icon-chevron-bas class="h-5 w-5" />
        </button>
        <ul
            class="dropdown-menu mx-auto h-72 bg-white ring-1 rounded-xl divide-y overflow-auto overflow-y-scroll"
        >
            <div
                class="sticky top-0 bg-white z-40 mt-0 flex border-gray-300 border rounded"
            >
                <input
                    class="pl-3 focus:ring-blue-500 border-0 rounded sm:text-md py-1"
                    :placeholder="$t('template.search')"
                    v-model="searchTemplate"
                />
            </div>
            <li
                v-for="(template, index) in templateList"
                :key="`templateOption_${template.id}`"
                class="flex items-center py-2"
            >
                <div class="dropdown-item" @click="changeContent(index)">
                    {{ template.response_title }}
                </div>
            </li>
        </ul>
        <router-link to="/admin/template/create">
            <button
                type="button"
                class="items-center inline-flex px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
                {{ $t('template.new') }}
            </button>
        </router-link>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    name: 'PostTemplate',
    data() {
        return {
            search: '',
            templateList: null
        }
    },
    methods: {
        ...mapActions('admin', ['getTemplates']),
        changeContent(index) {
            this.$emit('change-content', {
                content: this.templateList[index].response_content
            })
        },
        setTemplate(newValue) {
            let newTemplates = []
            this.templates.forEach((template) => {
                if (template.response_title.includes(newValue)) {
                    newTemplates.push(template)
                }
            })
            this.templateList = newTemplates
            this.search = newValue
        }
    },
    computed: {
        ...mapState('admin', {
            templates: (state) => state.templates
        }),
        searchTemplate: {
            get() {
                return this.search
            },
            set(newValue) {
                if (newValue.length < this.search.length) {
                    this.templateList = this.templates
                }
                this.setTemplate(newValue)
            }
        }
    },
    created() {
        this.getTemplates().then(() => {
            this.templateList = this.templates
        })
    }
}
</script>
