<template>
  <HistoryRow>
    <template v-slot:icon>
      <span
        class="h-8 w-8 rounded-full bg-info-500 flex items-center justify-center ring-8 ring-white text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
          />
        </svg>
      </span>
    </template>
    <div>
      <p class="text-sm text-gray-500">
        <a href="#" class="font-medium text-gray-900" v-if="history.user  && history.author_type===1">
          {{ history.user.name }}
        </a>
        <a href="#" class="font-medium text-gray-900" v-else-if="history.author_type===2 || !history.author_type">
          {{ history.citizen && history.citizen.name ? history.citizen.name : history.new_value && history.new_value.author_mail? history.new_value.author_mail :$t('post.page.history.citizen') }}
        </a>
        {{ $t(`post.page.history.message.${history.action}`) }}
      </p>
    </div>
    <div class="text-right text-sm whitespace-nowrap text-gray-500">
      {{ moment(history.created_at).format('D MMMM') }}
    </div>
  </HistoryRow>
</template>

<script>
import HistoryMixin from './HistoryMixin'

export default {
  name: 'PostHistory',
  mixins: [HistoryMixin],
}
</script>

<style scoped></style>
