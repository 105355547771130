<template>
  <li>
    <div class="relative pb-8">
      <span
        class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
        v-if="separator"
      />
      <div class="relative flex space-x-3">
        <div>
          <slot name="icon" />
        </div>
        <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
          <slot />
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'HistoryRow',
  props: {
    separator: {
      required: false,
      type: Boolean,
      default: true,
      description: 'display or hide vertical separator',
    },
  },
}
</script>

<style scoped></style>
