<template>
    <div>
        <b-table
            class="table-striped mt-5 is-fullwidth"
            :data="posts.data"
            :striped="true"
            :sticky-header="false"
            :mobile-cards="true"
            :onresize="screenWidth()"
        >
            <b-table-column
                width="1%"
                field="id"
                numeric
                v-slot="props"
                :label="$t('post.page.tablecolumnlabels.ref')"
            >
                {{ props.row.reference }}
            </b-table-column>

            <b-table-column
                :visible="isformVisible"
                width="14%"
                field="form.name"
                v-slot="props"
                :label="$t('post.page.tablecolumnlabels.form')"
            >
                {{ props.row.form.name }}
            </b-table-column>

            <b-table-column
                width="8%"
                field="status_id"
                v-slot="props"
                :label="$t('post.page.tablecolumnlabels.status')"
            >
                <ButtonEditStatus :post="props.row" />
            </b-table-column>
            <b-table-column
                width="40%"
                field="title"
                v-slot="props"
                :label="$t('post.page.tablecolumnlabels.subject')"
            >
                <router-link
                    :to="`/posts/${props.row.id}`"
                    class="cursor-pointer align-middle viewPostBtn"
                >
                    {{ props.row.title }}
                </router-link>
            </b-table-column>
            <b-table-column
                field="created_at"
                v-slot="props"
                :label="$t('post.page.tablecolumnlabels.date')"
            >
                {{ moment(props.row.created_at).locale('fr').format('L') }}
            </b-table-column>
            <b-table-column
                :visible="isoperatorVisible"
                field="created_by"
                v-slot="props"
                :label="$t('post.page.tablecolumnlabels.operator')"
            >
                <div v-for="(racis, index) in props.row.racis" :key="index">
                    <span
                        class="flex items-center"
                        v-if="racis.is_responsible == 1"
                        >{{ racis.name }}</span
                    >
                </div>
            </b-table-column>
            <b-table-column
                width="10%"
                field="priority"
                :label="$t('post.page.tablecolumnlabels.priority')"
                v-slot="props"
            >
                <ButtonEditPriority :post="props.row"></ButtonEditPriority>
            </b-table-column>
            <b-table-column
                width="10%"
                field="actions"
                v-slot="props"
                :label="$t('post.page.tablecolumnlabels.shares')"
            >
                <div
                    @click.prevent="showPostPreview(props.row)"
                    class="cursor-pointer inline-flex items-center px-3 py-2 mx-1 mb-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    <IconSearch class="h-4 w-4" />
                </div>
                <ButtonRemind variant="minimal" :post="props.row" />
            </b-table-column>
        </b-table>
        <PostPreviewModal ref="postPreview" :post="setSelectedPost" />
    </div>
</template>
<style scoped>
.loading {
    background-color: grey;
}
</style>
<script>
import ButtonRemind from '@/components/UI/ButtonRemind'
import { mapActions, mapState } from 'vuex'
import PostPreviewModal from '@/components/Model/Post/PostPreviewModal'
import ButtonEditStatus from '@/components/UI/ButtonEditStatus'
import ButtonEditPriority from './ButtonEditPriority'

export default {
    name: 'PostsTable',
    props: ['filterMyOpenedTickets', 'filterMyTrackedTickets', 'citizenPosts'],
    components: {
        PostPreviewModal,
        ButtonEditStatus,
        ButtonRemind,
        ButtonEditPriority
    },
    created() {
        this.config.per_page = '10'
        this.filterMyOpenedTickets
            ? (this.config.filters = this.filterMyOpenedTickets)
            : null
        this.filterMyTrackedTickets
            ? (this.config.filters = this.filterMyTrackedTickets)
            : null
        if (this.citizenPosts) {
            this.config.filters = this.citizenPosts
            this.config.per_page = ''
        }
        this.getPosts().then((response) => {
            this.posts = response.data.posts
        })
    },
    data() {
        return {
            indexClickedRow: 0,
            isLoadingStatus: false,
            isLoading: false,
            setSelectedPost: {},
            posts: [],
            desktop: false,
            isformVisible: false,
            isoperatorVisible: false
        }
    },
    computed: {
        config: {
            get() {
                return this.$store.state.post.postsConfig
            },
            set(value) {
                this.$store.commit('post/setPostsConfig', value)
            }
        }
    },
    methods: {
        ...mapActions('post', ['getPosts', 'getPost', 'updateStatus']),
        screenWidth(e) {
            if (screen.width < 728 || screen.width > 1240) {
                this.isformVisible = true
                this.isoperatorVisible = true
            } else {
                this.isformVisible = false
                this.isoperatorVisible = false
            }
        },
        showPostPreview(post) {
            this.getPost(post.id).then((result) => {
                this.setSelectedPost = result.data.post
            })
            this.$refs.postPreview.trigger()
        }
    }
}
</script>
