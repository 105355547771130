<template>
    <div>
        <b>{{ value.label }}</b
        ><br />
        <div v-if="value.value.lat && value.value.lon">
            {{ value.value.lat }}, {{ value.value.lon }}
        </div>
        <div v-else>
            {{ $t('error.location.precision') }}
        </div>
        {{ value.value.address }}
        <l-map v-if="value.value.lat && value.value.lon"
          id='myMap'
            ref="myMap"
            :zoom="zoom"
            :center="getCenter()"
            class="m-4 w-auto rounded-md"
            style="height: calc(100vh - 500px); z-index: 1"
            @update:zoom="updateZoom($event)"
        >
            <l-tile-layer
                :attribution="attribution"
                :url="url"
                :options="optionsZoom"
            />
            <v-marker-cluster>
                <l-marker :lat-lng="getCoord()">
                    <l-popup>
                        <PanelKanban
                            :post="post"
                            class="w-72 h-30"
                            :withStatus="true"
                        >
                        </PanelKanban>
                    </l-popup>
                </l-marker>
            </v-marker-cluster>
        </l-map>
    </div>
</template>

<script>
import { L, latLng, icon } from 'leaflet'
import {
  LMap,
  LTileLayer,
  LGeoJson,
  LPolygon,
  LMarker,
  LPopup,
  LIcon,
  LControl,
  LTooltip
} from 'vue2-leaflet'
import PanelKanban from '../../../components/UI/PanelKanban'

export default {
  name: 'MapPostCard',
  components: {
    L,
    LMap,
    LTileLayer,
    LPolygon,
    LMarker,
    LGeoJson,
    LPopup,
    LTooltip,
    LIcon,
    LControl,
    PanelKanban
  },
  props: ['post', 'value', 'isPostExport'],
  data: function() {
    var center
    return {
      preferCanvas: true,
      zoom: 16,
      url: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.VUE_APP_MAPBOX_ACCESS_TOKEN}`,
      attribution: '&copy; OpenStreetMap, &copy; Mapbox',
      center,
      optionsZoom: {
        maxZoom: 22,
        maxNativeZoom: 20
      }
    }
  },
  methods: {
    updateZoom: function(zoom) {
      this.zoom = zoom
    },
    getCoord: function() {
      return latLng(this.post.location.lat, this.post.location.lon)
    },
    getCenter: function() {
      return [this.post.location.lat, this.post.location.lon]
    }
  }
}
</script>

<style scoped></style>
