import axios from "axios";

export default class UploadAdapter {
    constructor(loader, token, baseURL) {
        // The file loader instance to use during the upload.
        this.loader = loader;
        this.token = token;
        this.baseURL = baseURL;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then(file => new Promise((resolve, reject) => {
                const data = new FormData();

                data.append('upload', file);
                axios({
                    method: "post",
                    url: `${this.baseURL}/media`,
                    data: data,
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                })
                    .then(function (response) {
                        resolve({
                            default: 'data:image/png;base64,' + response.data.media
                        });
                    })
                    .catch(function (response) {
                        reject(response && response.error ? response.error.message : `Couldn't upload file: ${file.name}.`);
                    });
            }));
    }
}
