<template>

  <div v-if='post'
    class="panel d-flex flex-row mb-0"
    data-panel-collapsed
    data-panel-fullscreen
    data-panel-close
    data-panel-locked
    data-panel-color
    data-panel-reset
    data-panel-refresh
    @click="openLinkPost()"
  >
    <div class="panel-hdr">
      <!-- Photo du ticket -->
      <div v-if="pictureMedias && pictureMedias.id" class="h-full w-1/4 p-0 m-0">
        <img
          class="h-full w-full  object-cover object-center rounded-l-sm"
          :src="`${baseUrl}/storage/${pictureMedias.id}?token=${token}`"
          alt="photo ticket Bouge Ma Ville"
        />
      </div>
      <div v-else class="h-full w-1/4 p-0 m-0">
        <img
          class="h-100 w-100 object-cover object-center rounded-l-sm"
          src="/img/img20.jpg"
          alt="photo ticket Bouge Ma Ville"
        />
      </div>
      <!-- Titre du ticket -->
      <div class="d-flex flex-col justify-around w-3/4 pr-1">
        <div class="ticketNumber d-flex w-full pl-2">
          <p>#{{ post.reference }}</p>
          <h2 class="pl-1 pr-1 mr-0">{{ post.title | truncate(52) }}</h2>
        </div>
        <div class="panel-toolbar"></div>
        <div class="panel-container collapse show w-100 p-0 mt-1">
          <div class="panel-content pb-0 pr-1">
            <div v-if="withStatus && post.status" class="text-sm text-dark">
              <i :class="post.status.icon"></i>
              {{ post.status.name }}
            </div>
            <p class="text-sm text-dark mt-1 mb-0" v-if='post.form.name'>
              <i class="far fa-file mr-1"></i>
              {{ post.form.name | capitalize | truncate(18) }}
            </p>
            <div class="flex flex-wrap my-1" v-if='post.created_at'>
              <p class="text-sm text-gray-400">
                <i class="far fa-clock mr-1 text-gray-400"></i
                >{{ moment(post.created_at).format('L') }}
              </p>
              <div class="flex ml-auto items-center">
                <span
                  @mouseover="isIconPriorityHover = true"
                  @mouseleave="isIconPriorityHover = false"
                >
                  <!-- priority observation -->
                  <template v-if="post.priority === 1">
                    <IconSearch class="h-5 w-5 text-blue-500" />
                    <span
                      v-show="isIconPriorityHover"
                      class="priorityName text-blue-700 text-sm"
                      >{{ $t(`post.page.priority.${post.priority}`) }}
                    </span>
                  </template>
                  <!-- priority mineur -->
                  <template v-if="post.priority === 2">
                    <IconCheck class="h-5 w-5 text-blue-500" />
                    <span
                      v-show="isIconPriorityHover"
                      class="priorityName text-blue-700 text-sm"
                      >{{ $t(`post.page.priority.${post.priority}`) }}
                    </span>
                  </template>

                  <!-- priority modere-->
                  <template v-if="post.priority === 3">
                    <IconProgress class="h-5 w-5 text-blue-500" />
                    <span
                      v-show="isIconPriorityHover"
                      class="priorityName text-blue-700 text-sm"
                      >{{ $t(`post.page.priority.${post.priority}`) }}
                    </span>
                  </template>
                  <!-- priority critique -->
                  <template v-if="post.priority === 4">
                    <IconFire class="h-5 w-5 text-blue-500" />
                    <span
                      v-show="isIconPriorityHover"
                      class="priorityName text-blue-700 text-sm"
                      >{{ $t(`post.page.priority.${post.priority}`) }}
                    </span>
                  </template>
                </span>

                <span class="text-dark ml-1" v-if="post.created_by && post.created_by.name">
                  <i
                    @mouseover="isIconAuthorHover = true"
                    @mouseleave="isIconAuthorHover = false"
                    class="text-base far fa-user"
                  ></i>
                  <p v-show="isIconAuthorHover" class="authorName text-sm">
                    {{ post.created_by.name }}
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="hasFooterSlot" class="rounded-bottom bg-faded text-muted">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<style scoped>
.panel-hdr {
  text-align: left;
}
.panel-hdr h2,
.ticketNumber {
  font-size: 0.925em;
  color: black;
  line-height: 1.5;
}
.panel-hdr h2 {
  font-weight: 400;
}
.ticketNumber {
  font-weight: 700;
}
.priorityName,
.authorName {
  position: absolute;
  bottom: 1em;
  right: 0;
  z-index: 9;
  background-color: white;
  margin-right: 2%;
  padding: 1%;
  border: 1px solid #e7ebe5;
  border-radius: 0.25em;
}
.authorName {
  color: #004085;
}
.priorityName {
  margin-bottom: 10%;
}
</style>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PanelKanban',
  props: {
    post: {
      required: true,
      type: Object,
    },
    withStatus: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data: function () {
    return {
      isIconAuthorHover: false,
      isIconPriorityHover: false,
      etc: '',
      baseUrl: this.$store.state.baseURL,
    }
  },
  created() {
  },
  computed: {
    ...mapState('auth', {
      token: (state) => state.token,
    }),
    hasFooterSlot() {
      return !!this.$slots['footer']
    },
    pictureMedias() {
      if(this.post && this.post.medias && this.post.medias.length && this.post.medias.length > 0){
        return this.post.medias.find((media) => media.name.includes('png') ||  media.name.includes('jpg')  ||  media.name.includes('jpeg'))
      }else{
        return null
      }
    },
  },
  methods: {
    openLinkPost() {
      this.$router.push(`/posts/${this.post.id}`)
    },
  },
}
</script>
