<template>
    <div class="panel">
        <div class="panel-hdr bg-white border-bottom">
            <slot name="header"></slot>
        </div>

        <div class="panel-container show">
            <slot></slot>
        </div>

        <div v-if="hasFooterSlot" class="rounded-bottom bg-faded text-muted">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "Panel",
    props: {
        'data-panel-sortable': {
            required: false,
            default: true
        },
        'data-panel-collapsed': {
            required: false,
            default: true
        },
        'data-panel-fullscreen': {
            required: false,
            default: true
        },
        'data-panel-close': {
            required: false,
            default: true
        },
        'data-panel-locked': {
            required: false,
            default: true
        },
        'data-panel-refresh': {
            required: false,
            default: true
        },
        'data-panel-reset': {
            required: false,
            default: true
        },
        'data-panel-color': {
            required: false,
            default: true
        },
    },
    components: {},
    data: function () {
        return {};
    },
    created() {
    },
    computed: {
        hasFooterSlot() {
            return !!this.$slots["footer"];
        }
    },
    methods: {},
    mounted() {
        //NOTE: J'ai commenté cette ligne de code pour enlever les boutons que l'on a utilisé dans une ancienne version
        // $(document).ready(function () {
        //     $("#js-page-content").smartPanel();
        // });
    },
};
</script>
