<template>
  <div>
    <b>{{ value.label }}</b><br/>
    {{ value.value.label }}
  </div>
</template>

<script>
export default {
  name: "ClassicAttribute",
  props:['value']
}
</script>

<style scoped>

</style>