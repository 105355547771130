<template>
    <section aria-labelledby="applicant-information-title">
        <div class="bg-white shadow sm:rounded-lg">
            <div class="px-4 py-5 sm:px-6">
                <h2
                    id="applicant-information-generales"
                    class="text-lg leading-6 font-medium text-blue-500"
                >
                    {{ $t('post.page.information.general') }}
                </h2>
            </div>
            <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500">
                            {{ $t('post.page.information.reference') }}
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900">
                            #{{ post.reference }}
                            <i
                                type="button"
                                v-clipboard:copy="post.id.toString()"
                                class="text-base text-blue-500 far fa-copy"
                            >
                            </i>
                        </dd>
                    </div>
                    <div class="sm:col-span-2" v-if="post.content">
                        <dt class="text-sm font-medium text-gray-500">
                            {{ $t('post.page.information.information') }}
                        </dt>
                        <div
                            class="mt-1 text-sm text-gray-900 break-words flex space-x-1 items-center"
                        >
                            <div v-html="formatContent(post.content)"></div>
                            <i
                                type="button"
                                v-clipboard:copy="
                                    post.content
                                        .replace(/(<([^>]+)>)/gi, ' ')
                                        .replace(/\&nbsp;/g, '')
                                "
                                class="text-base text-blue-500 far fa-copy"
                            >
                            </i>
                        </div>
                    </div>
                </dl>
            </div>
        </div>
    </section>
</template>

<style scoped>
@media (max-width: 640px) {
    .fa-copy {
        display: none;
    }
}
</style>
<script>
export default {
    name: 'PostInfo',
    props: ['post'],
    methods: {
        formatContent(message) {
            return unescape(message)
        }
    }
}
</script>

<style></style>
