<template>
    <section aria-labelledby="applicant-information-title" class="py-4" v-if='isOneAdminAnswer()'>
        <div class="bg-white shadow sm:rounded-lg">
            <div class="px-4 py-5 sm:px-6 border-b border-gray-200 bg-blue-500">
                <h2
                    id="applicant-information-generales"
                    class="text-lg leading-6 font-medium text-white"
                >
                    {{ $t('post.page.information.admin') }}
                </h2>
            </div>
            <div v-for="answer in post.answers" :key="answer.id">
                <div
                    class="px-4 py-5 sm:px-6 divide-y divide-gray-200"
                    v-if="answer.hidden === 1"
                >
                    <article class="px-4">
                        <component
                            :medias="post.medias"
                            :is="answer.name"
                            :value="answer"
                            :post="post"
                        />
                    </article>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import MapPostCard from '@/components/Model/Map/MapPostCard'
import ClassicAttribute from './Attribute/ClassicAttribute'
import CategoryAttribute from './Attribute/CategoryAttribute'
import FileUploadAttribute from './Attribute/FileUploadAttribute'
import CheckboxAttribute from './Attribute/CheckboxAttribute'

export default {
    name: 'PostAdmin',
    components: {
        location: MapPostCard,
        category: CategoryAttribute,
        gdpr: CheckboxAttribute,
        smalltext: ClassicAttribute,
        email: ClassicAttribute,
        markdown: ClassicAttribute,
        'file-upload': FileUploadAttribute,
        rating: ClassicAttribute,
        'select-field': ClassicAttribute,
        checkbox: CheckboxAttribute,
        radio: CheckboxAttribute,
        date: ClassicAttribute,
        datetime: ClassicAttribute,
        number: ClassicAttribute
    },
    props: ['post'],
  methods: {
      isOneAdminAnswer() {
        return this.post.answers.filter(answer => answer.hidden === 1).length > 0
      }
  }
}
</script>

<style scoped></style>
