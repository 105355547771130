<template>
  <HistoryRow>
    <template v-slot:icon>
      <span
        class="h-8 w-8 rounded-full bg-warning-500 flex items-center justify-center ring-8 ring-white text-white"
      >
        <IconNotification class="w-5 h-5 text-white"></IconNotification>
      </span>
    </template>

    <div>
      <p class="text-sm text-gray-500">
        <a href="#" class="font-medium text-gray-900" v-if="history.user">
          {{ history.user.name }}
        </a>
        {{ $t(`post.page.history.status.${history.action}`) }}
        {{ $t('post.page.history.from') }}
        "{{ getOldStatusName.name.toLowerCase() }}"
        {{ $t('post.page.history.to') }}
        "{{ getNewStatusName.name.toLowerCase() }}"
      </p>
    </div>

    <div class="text-right text-sm whitespace-nowrap text-gray-500">
      {{ moment(history.created_at).format('D MMMM') }}
    </div>
  </HistoryRow>
</template>

<script>
import { mapState } from 'vuex'
import HistoryMixin from './HistoryMixin'

export default {
  name: 'StatusHistory',
  mixins: [HistoryMixin],

  computed: {
    ...mapState('status', {
      status: (state) => state.formTypeStatuses,
    }),
    getNewStatusName() {
      return this.getTranslationName(
        this.history.new_value.formTypeId,
        this.history.new_value.status
      )
    },
    getOldStatusName() {
      return this.getTranslationName(
        this.history.old_value.formTypeId,
        this.history.old_value.status
      )
    },
  },
  methods: {
    getTranslationName(formTypeId, value) {
      let result = this.status[formTypeId].find(status=>status.id==value)
      return result.translations.find(
        (translation) => translation.locale === this.$store.state.lang
      )
    },
  },
}
</script>

<style scoped></style>
