<template>
    <panel id="panel-2" :class="{ panelPostRacis: formpopup }">
        <template v-slot:header>
            <h2>
                <span class="icon-stack fs-xxl mr-2">
                    <i
                        class="base base-7 icon-stack-4x opacity-100 color-primary-700"
                    ></i>
                    <i
                        class="fal fa-user icon-stack-2x opacity-100 color-white"
                    ></i>
                </span>
                {{ $t('post.page.racis.title') }}
            </h2>
        </template>
        <div class="panel-container collapse show" style="">
            <div class="panel-content border-bottom-0">
                <div class="panel-tag mb-4">
                    {{ $t('post.page.racis.description') }}
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <h4>{{ $t('post.page.racis.is_responsible') }}</h4>
                        <v-select
                            multiple
                            v-model="responsible"
                            :filterable="false"
                            :options="options"
                            label="name"
                            @search="onSearch"
                        >
                            <template slot="no-options">
                                {{ $t('post.page.racis.searchuser') }}
                            </template>
                            <template slot="option" slot-scope="option">
                                <div class="row m-0 p-0 w-100">
                                    <div
                                        class="selected d-flex justify-content-center"
                                    >
                                        {{ option.name }}
                                    </div>
                                </div>
                            </template>
                            <template
                                slot="selected-option"
                                slot-scope="option"
                            >
                                <div
                                    class="selected d-flex justify-content-center"
                                >
                                    {{ option.name }}
                                </div>
                            </template>
                        </v-select>
                    </div>

                    <div class="col-md-12 mt-3">
                        <h4>{{ $t('post.page.racis.is_accountable') }}</h4>
                        <v-select
                            v-model="accountable"
                            :filterable="false"
                            :options="options"
                            label="name"
                            multiple
                            @search="onSearch"
                        >
                            <template slot="no-options">
                                {{ $t('post.page.racis.searchuser') }}
                            </template>
                            <template slot="option" slot-scope="option">
                                <div class="row m-0 p-0 w-100">
                                    <div
                                        class="selected d-flex justify-content-center"
                                    >
                                        {{ option.name }}
                                    </div>
                                </div>
                            </template>
                            <template
                                slot="selected-option"
                                slot-scope="option"
                            >
                                <div
                                    class="selected d-flex justify-content-center"
                                >
                                    {{ option.name }}
                                </div>
                            </template>
                        </v-select>
                    </div>

                    <div class="col-md-12 col-lg-12 col-xl-6 mt-5">
                        <h4>{{ $t('post.page.racis.editconsulted') }}</h4>
                        <v-select
                            v-model="consulted"
                            :filterable="false"
                            :options="options"
                            label="name"
                            @search="onSearch"
                            multiple
                        >
                            <template slot="no-options">
                                {{ $t('post.page.racis.searchuser') }}
                            </template>
                            <template slot="option" slot-scope="option">
                                <div
                                    class="selected d-flex justify-content-center"
                                >
                                    {{ option.name }}
                                </div>
                            </template>
                            <template
                                slot="selected-option"
                                slot-scope="option"
                            >
                                <div
                                    class="selected d-flex justify-content-center"
                                >
                                    {{ option.name }}
                                </div>
                            </template>
                        </v-select>
                    </div>

                    <div class="col-md-12 col-lg-12 col-xl-6 mt-5">
                        <h4>{{ $t('post.page.racis.editinformed') }}</h4>
                        <v-select
                            v-model="informed"
                            :filterable="false"
                            :options="options"
                            label="name"
                            @search="onSearch"
                            :clearable="false"
                            multiple
                        >
                            <template slot="no-options">
                                {{ $t('post.page.racis.searchuser') }}
                            </template>
                            <template slot="option" slot-scope="option">
                                <div class="row m-0 p-0 w-100">
                                    <div
                                        class="selected d-flex justify-content-center"
                                    >
                                        {{ option.name }}
                                    </div>
                                </div>
                            </template>
                            <template
                                slot="selected-option"
                                slot-scope="option"
                            >
                                <div
                                    class="selected d-flex justify-content-center"
                                >
                                    {{ option.name }}
                                </div>
                            </template>
                        </v-select>
                    </div>
                </div>
            </div>
        </div>
    </panel>
</template>

<script>
import Panel from '@/components/UI/Panel'
import { mapState, mapActions } from 'vuex'
import ModalMixin from '@/components/UI/ModalMixin'
import { now } from 'moment'

export default {
    name: 'FormRacisForm',
    components: {
        Panel
    },
    props: ['editform', 'formpopup'],
    mixins: [ModalMixin],
    data() {
        return {
            id: 'formRacisForm',
            options: [],
            loading: false,
            oldIsResponsible: null,
            oldIsAccountable: null,
            oldIsConsulted: [],
            oldIsInformed: [],
            result: [],
            iscreated: false,
            responsible: null,
            accountable: null
        }
    },
    async created() {
        this.iscreated = true
        const formId = this.$route.params.formId
        if (formId) await this.editForm(formId)
        if (this.racisresult) {
            this.responsible =
                this.userIsResponsible && this.userIsResponsible.length > 0
                    ? this.userIsResponsible
                    : null
            this.accountable =
                this.userIsAccountable && this.userIsAccountable.length > 0
                    ? this.userIsAccountable
                    : null
        }
    },
    watch: {
        responsible: function (newValue) {
            if (newValue) {
                if (
                    this.userIsResponsible &&
                    this.userIsResponsible.length > 0
                ) {
                    this.userIsResponsible.forEach((element) =>
                        this.changeRole(this.racisresult, element.id, 0)
                    )
                }

                newValue.map((value) => {
                    this.pushUsers(this.racisresult, value, 0)
                })
                this.oldIsResponsible = newValue
                return newValue
            } else {
                this.changeRole(this.racisresult, this.oldIsResponsible.id, 0)
                this.userIsResponsible.forEach((element) =>
                    this.changeRole(this.racisresult, element.id, 0)
                )
                return null
            }
        },
        accountable: function (newValue) {
            if (newValue) {
                if (
                    this.userIsAccountable &&
                    this.userIsAccountable.length > 0
                ) {
                    this.userIsAccountable.forEach((element) =>
                        this.changeRole(this.racisresult, element.id, 1)
                    )
                }
                newValue.map((value) => {
                    this.pushUsers(this.racisresult, value, 1)
                })
                this.oldIsAccountable = newValue
                return newValue
            } else {
                this.userIsAccountable.forEach((element) =>
                    this.changeRole(this.racisresult, element.id, 1)
                )
                this.changeRole(this.racisresult, this.oldIsAccountable.id, 1)
                return null
            }
        }
    },
    computed: {
        ...mapState('form', {
            form: (state) => state.newForm
        }),
        ...mapState('post', {
            post: (state) => state.post
        }),
        racisresult() {
            if (this.formpopup) {
                return this.post.racis && this.post.racis.length > 0
                    ? Object.assign([], this.post.racis)
                    : []
            } else {
                return this.form.racis && this.form.racis.length > 0
                    ? this.form.racis
                    : []
            }
        },
        userIsResponsible() {
            return this.racisresult.filter((user) => user.is_responsible === 1)
        },
        userIsAccountable() {
            return this.racisresult.filter((user) => user.is_accountable === 1)
        },
        consulted: {
            get() {
                if (this.iscreated) {
                    this.oldIsConsulted = this.racisresult.filter(
                        (user) => user.is_consulted === 1
                    )
                    return this.oldIsConsulted
                }
            },
            set(newValue) {
                if (newValue) {
                    if (newValue.length > this.oldIsConsulted.length) {
                        newValue.forEach((element) => {
                            this.pushUsers(this.racisresult, element, 2)
                        })
                    } else if (newValue.length < this.oldIsConsulted.length) {
                        let deletevalue = this.oldIsConsulted.filter(
                            this.compare(newValue)
                        )
                        deletevalue.forEach((element) => {
                            this.changeRole(this.racisresult, element.id, 2)
                        })
                    }
                }
                this.oldIsConsulted = newValue
            }
        },
        informed: {
            get() {
                if (this.iscreated) {
                    this.oldIsInformed = this.racisresult.filter(
                        (user) => user.is_informed === 1
                    )
                    return this.oldIsInformed
                }
            },
            set(newValue) {
                if (newValue) {
                    if (newValue.length > this.oldIsInformed.length) {
                        newValue.forEach((element) => {
                            this.pushUsers(this.racisresult, element, 3)
                        })
                    } else if (newValue.length < this.oldIsInformed.length) {
                        let deletevalue = this.oldIsInformed.filter(
                            this.compare(newValue)
                        )
                        deletevalue.forEach((element) => {
                            this.changeRole(this.racisresult, element.id, 3)
                        })
                    }
                }
                this.oldIsInformed = newValue
            }
        }
    },

    methods: {
        ...mapActions('auth', {
            searchUser: 'searchUser'
        }),
        ...mapActions('post', {
            updatePostRacis: 'updatePostRacis'
        }),
        ...mapActions('form', { editForm: 'editForm' }),
        onSearch(search, loading) {
            if (search.length) {
                loading(true)
                this.search(loading, search)
            }
        },
        compare(otherArray) {
            return function (current) {
                return (
                    otherArray.filter(function (other) {
                        return other.id == current.id
                    }).length == 0
                )
            }
        },
        search: function (loading, search) {
            this.searchUser(search).then((response) => {
                this.options = response.data.users.data
                loading(false)
            })
        },
        pushUsers(form, newValue, index) {
            if (!newValue.id) {
                newValue.id = newValue.id
            }
            if (newValue && newValue.id) {
                var i = form.findIndex((user) => user.id === newValue.id)
                this.pushUser(form, newValue, index, i)
            }
        },
        pushUser(form, newValue, index, i) {
            if (i === -1) {
                this.formatNewValue(newValue)
                this.applyRole(newValue, index, 1)
                form.push(newValue)
            } else {
                this.applyRole(form[i], index, 1)
            }
        },
        formatNewValue(newValue) {
            newValue = {
                id: newValue.id,
                name: newValue.name,
                user: {
                    name: newValue.name,
                    email: newValue.email,
                    job_title: newValue.job_title
                },
                is_responsible: 0,
                is_accountable: 0,
                is_consulted: 0,
                is_informed: 0
            }
        },
        addValue(value) {
            return value === 1 ? 1 : 0
        },
        applyRole(form, index, apply) {
            form.is_responsible = this.addValue(form.is_responsible)
            form.is_accountable = this.addValue(form.is_accountable)
            form.is_consulted = this.addValue(form.is_consulted)
            form.is_informed = this.addValue(form.is_informed)
            switch (index) {
                case 0:
                    form.is_responsible = apply
                    break
                case 1:
                    form.is_accountable = apply
                    break
                case 2:
                    form.is_consulted = apply
                    break
                case 3:
                    form.is_informed = apply
                    break
            }
        },
        changeRole(form, userid, index) {
            if (userid) {
                var i = form.findIndex((user) => user.id === userid)
                this.applyRole(form[i], index, 0)
                if (
                    form[i].is_responsible === 0 &&
                    form[i].is_accountable === 0 &&
                    form[i].is_consulted === 0 &&
                    form[i].is_informed === 0
                ) {
                    form.splice(i, 1)
                }
            }
        },
        trigger: function () {
            this.modal.show()
        },
        cancel: function () {
            this.modal.hide()
        },
        update: function () {
            const id = this.$route.params.id
            this.updatePostRacis({ id, data: { racis: this.racisresult } })
                .then(() => {
                    this.$emit('get-post-data')
                    this.cancel()
                })
                .catch((e) => {
                    this.$store.dispatch('dispatchError', e)
                    this.loading = true
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },
    mounted() {
        if (this.formpopup) {
            this.create(this.id, {
                keyboard: false
            })
        }
    }
}
</script>

<style>
.panelPostRacis {
    box-shadow: none;
    border: none;
}
</style>
