<template>
  <HistoryRow>
    <template v-slot:icon>
      <span
        class="h-8 w-8 rounded-full bg-info-700 flex items-center justify-center ring-8 ring-white text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
    </template>
    <div>
      <p class="text-sm text-gray-500">
        <a href="#" class="font-medium text-gray-900" v-if="history.user">
          {{ history.user.name }}
        </a>

        {{ $t(`post.page.history.comment.${history.action}`) }}
      </p>
    </div>
    <div class="text-right text-sm whitespace-nowrap text-gray-500">
      {{ moment(history.created_at).format('D MMMM') }}
    </div>
  </HistoryRow>
</template>

<script>
import HistoryMixin from './HistoryMixin'

export default {
  name: 'PostHistory',
  mixins: [HistoryMixin],
}
</script>

<style scoped></style>
