<template>
  <section aria-labelledby='profile-overview-title'>
    <div class='rounded-lg bg-white overflow-hidden shadow mt-5'>
      <div class='bg-white p-4'>
        <div class='sm:flex sm:items-center sm:justify-between'>
          <div class='sm:flex sm:space-x-5'>
            <div class='hidden sm:flex'>
              <IconUserPlain class='h-20 w-20' />
            </div>
            <div
              class='flex items-center justify-content-center mt-2 text-center sm:mt-0 sm:pt-1 sm:text-left'
            >
              <p
                class='text-sm font-medium text-gray-600 text-left mt-2'
              >
                <span>{{ $t('post.page.open_by') }} </span>
                <span
                  v-if='
                                        !(post.created_by || post.author_email)
                                    '
                  @click.prevent='openCitizenProfile()'
                  :class="
                                        post.author_type === 2
                                            ? 'cursor-pointer'
                                            : ''
                                    "
                  class='ext-blue-500 font-bold'
                >
                                    {{ $t('post.page.unknown_citizen') }}
                                </span>
                <span v-else-if='!isAnonymousCitizen()'>
                                    {{ $t('post.page.the_operator') }}
                                    <span>{{ post.created_by.name }} </span
                                    ><br />
                                    {{ $t('post.page.for_the_citizen') }}
                                    <span
                                      @click.prevent='openCitizenProfile()'
                                      :class="
                                            post.author_type === 2
                                                ? 'cursor-pointer'
                                                : ''
                                        "
                                      class='ml-2 text-lg font-bold text-blue-500 sm:text-2xl text-left'
                                    >{{ post.author_email }}</span
                                    >
                                    <i
                                      type='button'
                                      v-clipboard:copy='post.author_email'
                                      class='ml-2 text-xl text-blue-500 far fa-copy'
                                    >
                                    </i>
                                </span>
                <span
                  v-else-if='
                                        !post.created_by && post.author_email
                                    '
                >
                                    {{ $t('post.page.the_citizen') }}
                                    <span
                                      @click.prevent='openCitizenProfile()'
                                      :class="
                                            post.author_type === 2
                                                ? 'cursor-pointer'
                                                : ''
                                        "
                                      class='ml-2 text-lg font-bold text-blue-500 sm:text-2xl text-left'
                                    >{{ post.author_email }}</span
                                    >
                                    <i
                                      type='button'
                                      v-clipboard:copy='post.author_email'
                                      class='ml-2 text-xl text-blue-500 far fa-copy'
                                    >
                                    </i>
                                </span>
                <span
                  v-else-if='
                                        post.author_type === 2 &&
                                        (post.author_email || post.created_by)
                                    '
                >
                                    {{ $t('post.page.the_citizen') }}
                                    <span
                                      @click.prevent='openCitizenProfile()'
                                      v-if='post.created_by'
                                      class='ml-2 text-lg font-bold text-blue-500 sm:text-2xl text-left'
                                    >
                                        {{ post.created_by.name }}
                                    </span>
                                    <span
                                      v-else
                                      @click.prevent='openCitizenProfile()'
                                      class='ml-2 text-lg font-bold text-blue-500 sm:text-2xl text-left'
                                    >
                                        {{ post.author_email }}
                                    </span>
                                    <i
                                      type='button'
                                      v-clipboard:copy='
                                            post.created_by
                                                ? post.created_by.name
                                                : post.author_email
                                        '
                                      class='ml-2 text-xl text-blue-500 far fa-copy'
                                    >
                                    </i>
                                </span>
                <span v-else>
                                    {{ $t('post.page.the_operator') }}
                                    <span
                                      @click.prevent='openCitizenProfile()'
                                      :class="
                                            post.author_type === 2
                                                ? 'cursor-pointer'
                                                : ''
                                        "
                                      class='ml-2 text-lg font-bold text-blue-500 sm:text-2xl text-left'
                                    >{{ post.created_by.name }}</span
                                    >
                                    <i
                                      type='button'
                                      v-clipboard:copy='post.created_by.name'
                                      class='ml-2 text-xl text-blue-500 far fa-copy'
                                    >
                                    </i>
                                </span>
              </p>
            </div>
          </div>
          <div
            class='mt-5 flex justify-center sm:mt-0'
            v-if='post.author_type === 1'
          ></div>
          <div
            class='mt-5 flex justify-center sm:mt-0'
            v-else-if='post.author_type === 2 || !post.author_type && (post.citizen_id || post.device_id) '
          >
            <button
              @click.prevent='openCitizenProfile()'
              class='flex justify-center items-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
            >
              {{ $t('post.page.button.profile') }}
            </button>
            <CitizenProfile ref='citizenProfile' :post='post' />
          </div>
          <div class='mt-5 flex justify-center sm:mt-0' v-else>
            <b-tag class='is-info'>
              {{ $t('post.page.citizen') }}
            </b-tag>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
@media (max-width: 640px) {
  .fa-copy {
    display: none;
  }
}
</style>

<script>
import CitizenProfile from '@/components/Model/Citizen/CitizenProfile.vue'

export default {
  name: 'PostOpenedBy',
  components: {
    CitizenProfile
  },
  props: ['post'],
  data() {
    return {}
  },
  methods: {
    openCitizenProfile() {
      this.$refs.citizenProfile.trigger()
    },
    isAnonymousCitizen() {
      if (
        this.post.author_type == 1 &&
        this.post.author_email != this.post.created_by.email
      ) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style></style>
