<template>
  <HistoryRow>
    <template v-slot:icon>
      <span
        class="h-8 w-8 rounded-full bg-warning-500 flex items-center justify-center ring-8 ring-white text-white"
      >
        <IconNotification class="w-5 h-5 text-white"></IconNotification>
      </span>
    </template>

    <div>
      <p class="text-sm text-gray-500">
        <a href="#" class="font-medium text-gray-900" v-if="history.user">
          {{ history.user.name }}
        </a>
        {{ $t(`post.page.history.priority.${history.action}`) }}
        {{ $t('post.page.history.from') }}
        "{{ $t(`post.page.priority.${history.old_value}`).toLowerCase() }}"
        {{ $t('post.page.history.to') }}
        "{{ $t(`post.page.priority.${history.new_value}`).toLowerCase() }}"
      </p>
    </div>

    <div class="text-right text-sm whitespace-nowrap text-gray-500">
      {{ moment(history.created_at).format('D MMMM') }}
    </div>
  </HistoryRow>
</template>

<script>
import HistoryMixin from './HistoryMixin'

export default {
  name: 'PriorityHistory',
  mixins: [HistoryMixin],
}
</script>

<style scoped></style>
