<template>
  <!-- POC RACIS -->
  <div class='bg-white shadow sm:rounded-lg'>
    <div class='px-4 py-5 sm:px-6' v-if='isPostRaciLoaded' >
      <div class='flex justify-between items-center mb-5' >
        <h2
          id='applicant-information-generales'
          class='text-lg leading-6 font-medium text-blue-500'
        >
          {{ $t('post.page.racis.title') }}
        </h2>
        <div @click='openRacisForm' >
          <icon-modif class='h-6 w-6 cursor-pointer' />
        </div>
      </div>
      <div >
        <ul role='list' v-if='post.racis' >
          <form-racis
            v-for='(racis, index) in post.racis'
            :key='index'
            :postracis='racis'
          />
        </ul>
      </div>
    </div>
    <bs-modal :id='id' type='modal-dialog'>
      <template #header>
        <div
          class='h-20 text-xl justify-between bg-blue-600 flex items-center'
        >
          <h6 class='m-0 text-white has-text-weight-bold ml-5'>
            {{ $t('post.page.racis.edit') }}
          </h6>
          <div @click='closeRacisForm' class='cursor-pointer'>
            <i class='fal fa-times text-white mr-4'></i>
          </div>
        </div>
      </template>
      <FormRacisForm
        class='modalContent'
        @get-post-data='getPostData'
        ref='formRacisForm'
        :formpopup='true'
      />
      <template #footer>
        <div
          class='modalFooter flex inline-flex justify-center items-center'
        >
          <button
            type='button'
            @click='closeRacisForm'
            class='inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-2'
          >
            {{ $t('post.page.racis.cancel') }}
          </button>
          <button
            type='button'
            @click='updateRacisForm'
            class='inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
          >
            {{ $t('post.page.racis.register') }}
          </button>
        </div>
      </template>
    </bs-modal>
  </div>
</template>
<script>

import FormRacisForm from '@/components/Model/Form/FormComponents/FormRacisForm.vue'
import FormRacis from '@/components/Model/Form/FormRacis.vue'
import BsModal from '@/components/UI/BsModal'
import { mapState,mapActions } from 'vuex'


export default {
  name: 'PostUsers',
  components: {
    FormRacisForm,
    FormRacis,
    BsModal
  },
  created() {
    this.loading = true
  },
  data() {
    return {
      id: 'formRacisForm'
    }
  },
  computed: {
    ...mapState('post', ['post']),
    isPostRaciLoaded: {
      get() {
        return this.$store.state.post.isPostRaciLoaded
      },
      set(value) {
        this.$store.commit('post/setIsPostRaciLoaded', value)
      }
    }
  },
  methods: {
    ...mapActions('post', ['getPostHistories']),
    getPostData() {
      this.$emit('get-post-data')
    },
    openRacisForm() {
      this.$refs.formRacisForm.trigger()
      this.isPostRaciLoaded = false
    },
    closeRacisForm() {
      this.$refs.formRacisForm.cancel()
      this.isPostRaciLoaded = true
    },
    updateRacisForm() {
      this.$refs.formRacisForm.update()
      this.getPostHistories(this.post.id)
    }
  }
}
</script>
<style>
.modalContent {
  z-index: 9999;
}

.modalFooter {
  z-index: 0;
}
</style>
